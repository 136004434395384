import PropTypes from "prop-types";
import React, { useState, useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

// inicializa categoria
if (localStorage.getItem('id_categoria') !== undefined && localStorage.getItem('id_categoria')){
  // la variable existe
} else {
  localStorage.setItem("id_categoria",1);
}

//comprobar el estado
if (localStorage.getItem("estado") !== undefined && localStorage.getItem("estado")){
} else {
  localStorage.setItem("estado",true)
}

// home pages
const HomeFashionThree = lazy(() => import("./pages/home/HomeFashionThree"));


// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));


// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const Panel = lazy(() => import("./pages/Panel/Panel"));

const App = (props) => {
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const refresh = () => window.location.reload(true)



// eslint-disable-next-line
  useEffect(() => {
    const visitedBefore = localStorage.getItem("visitedBefore");
  if (visitedBefore) {
    setShowWelcomeMessage(false);
  } else {
    setShowWelcomeMessage(true);
    localStorage.setItem("visitedBefore", "true");
  }
    props.dispatch(
      loadLanguages({
        languages: {
          es: require("./translations/spanish.json"),
          en: require("./translations/english.json")
          // fn: require("./translations/french.json"),
          // de: require("./translations/germany.json")
          
        }
      })
    );
  });

  const idSocio = localStorage.getItem("id_socio");
  const panelPath = process.env.PUBLIC_URL + "/panel";


  return (
    <ToastProvider placement="bottom-left">
      
      <div className="welcome-message">
        <div className="header-welcome-message">
          <img className="logoheader-welcome-message" alt="logo del centro municipal de emprendedores" src="/assets/img/logo/logo centro emprendedores.png"></img>
          {/* <button className="close-welcome-message" onClick={refresh}><i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button> */}
        </div>
        <div className="title-welcome-message">
          <img class="logotitle-welcome-message" alt="logo de mercadomid" src="/assets/img/logo/Mercadomid_logo2.png"></img>
        </div>
        <div className="body-welcome-message">
        EN CONSTRUCCIÓN
        </div>
      </div>
  
      
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(App);